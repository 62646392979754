<template>
  <DashboardInformationWrapperStyled :hasPartnerName="hasPartnerName">
    <header>
      <WidgetHeaderMolecule :title="$t('widgetTypes.DASHBOARD_INFORMATION')" :assetDimensions="[]" />
      <h2 v-if="title">{{ title }}</h2>
    </header>
    <main>
      <DashboardInformationDetailsStyled>
        <div v-for="(item, i) in mappedDashboardDetails" :key="i">
          <DashboardInfoKeyValueAtom :keyString="item.label" :valueString="item.value" />
        </div>
      </DashboardInformationDetailsStyled>
      <LogoAtom :path="imagePath" v-if="hasPartnerName" />
    </main>
  </DashboardInformationWrapperStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { DateTime } from 'luxon'
import 'vue-json-pretty/lib/styles.css'

import { flexColumns } from '@styles/mixins'
import WidgetHeaderMolecule from '@/components/Atomic/Molecules/WidgetHeaderMolecule'
import DashboardInfoKeyValueAtom from '@/components/Atomic/Atoms/DashboardInfoKeyValueAtom.vue'
import LogoAtom from '@/components/Atomic/Atoms/LogoAtom.vue'

const DashboardInformationWrapperStyled = styled('div')`
  ${flexColumns}
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 1rem;
  overflow: 'hidden';
  gap: 1rem;
  > header {
    ${flexColumns}
    > div {
      padding: 0;
    }
    h2 {
      margin: 0;
    }
  }
  > main {
    display: grid;
    grid-template-columns: ${p => (p.hasPartnerName ? '1fr 1fr' : '1fr')};
  }
`
const DashboardInformationDetailsStyled = styled('div')`
  display: grid;
  grid-template-rows: repeat(5, 1fr);
  grid-template-columns: 1fr;
  grid-gap: 0.85rem;
  > div {
    display: flex;
  }
`
export default {
  inject: ['uiSettings', 'theme'],
  name: 'DashboardInformation',
  props: {
    widget: {
      type: Object,
      required: true,
    },
    dashboardDetails: {
      type: Object,
      required: true,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    dashboardAssets: {
      type: Number,
      required: true,
    },
  },
  components: {
    DashboardInformationWrapperStyled,
    DashboardInformationDetailsStyled,
    DashboardInfoKeyValueAtom,
    LogoAtom,
    WidgetHeaderMolecule,
  },
  computed: {
    title() {
      return this.widget.title ? this.widget.title : this.hasPartnerName ? this.widget?.widgetSettings?.name : ''
    },
    mappedDashboardDetails() {
      const details = [
        { label: this.$tc('dashboardType'), value: this.$t(`dashboard.types.${this.dashboardDetails.type.toLowerCase()}`) },
        { label: this.$tc('createdAt'), value: this.dateTimeFormatter(this.dashboardDetails.createdAt) },
        { label: this.$tc('updatedAt'), value: this.dateTimeFormatter(this.dashboardDetails.updatedAt) },
        { label: this.$tc('noOfAssets'), value: this.dashboardAssets + '' },
        { label: this.$tc('noOfSharedUsers'), value: this.dashboardDetails?.shares?.length + '' },
      ]
      return details
    },
    hasPartnerName() {
      return this.widget?.widgetSettings.name && this.widget?.widgetSettings?.name !== 'NO_LOGO'
    },
    imagePath() {
      const partnerName = this.widget?.widgetSettings?.name
      return partnerName ? `/partners/${this.imageGenerator(partnerName)}.png` : ''
    },
    locale() {
      const ds = this.uiSettings?.dates ?? 'DE_DE'
      return ds.replace('_', '-').toLowerCase()
    },
  },
  methods: {
    dateTimeFormatter(date) {
      const localTime = DateTime.fromISO(date).setLocale(this.locale)
      return `${localTime.toLocaleString({ ...DateTime.DATETIME_SHORT, ...DateTime.TIME_WITH_SECONDS })}`
    },
    imageGenerator(partnerName) {
      let imageName = ''
      switch (partnerName) {
        case 'DEUTZ':
          imageName = 'DEUTZ'
          break
        case 'EPIROC':
          imageName = this.theme.isDark ? 'EPIROCDark' : 'EPIROCLight'
          break
        case 'SANDVIK':
          imageName = 'SANDVIK'
          break
        case 'SMAG':
          imageName = this.theme.isDark ? 'SMAGDark' : 'SMAGLight'
          break
        case 'GHH':
          imageName = 'GHH'
          break
        case 'BRIDGESTONE':
          imageName = 'BRIDGESTONE'
          break
        case 'TALPA':
          imageName = this.theme.isDark ? 'TalpaDark' : 'TalpaLight'
          break
      }

      return imageName
    },
  },
}
</script>
